import React, { useEffect, useState } from "react";
import ScratchCard from "./scratch/scratchCardGame";
import Background from "./scratch/scratchCardBG";
import GlobalStyle from "../Fonts/GlobalStyle";
import styled, { keyframes } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../modal/modal";
import Headline from "./Headline/Headline";
import frame from "./assets/frame.svg";
import Cover from "./assets/cover.svg";
import BG from "./assets/Background.svg";

const Main = styled.div`
  position: relative;
  background-color: #232426;
  border-radius: 23px;
  padding: 10px 0px 0px 0px;
  border: transparent;
  height: 100%;
`;

const Scratch = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background-image: url(${BG});
  padding-bottom: 35px;
    border-bottom-right-radius: 20px; 
  border-bottom-left-radius: 20px; 
`;

const CardDiv = styled.div`
  position: absolute;
  top: 0;
  display: block;
  margin: auto;
  width: 100%;
  background-image: url(${frame});
  background-size: contain;
  height: 450px;
  background-repeat: no-repeat;
  background-position: center center; /* Centers the background image */



  

  @media screen and (min-width: 768px) {
    width: 414px;
    display: block;
    margin: auto;
  }
`;

const ScratchCardDiv = styled.div`
  margin-top: 113px;
`;

const BackgroundDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonDiv = styled.div`
  // background-image: url(https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/d2b0899e-48ac-4c75-be8a-4445d54adf00/public);
  background-size: 100% 100%;
  display: block;
  margin: 5px auto 0px auto;
  z-index: 10000;
  width: 100;

  button {
    background: none;
    border: none;
    display: block;
    margin: auto;

    img {
      width: 280px;
      display: block;
      margin: auto;
    }
  }

  @media screen and (min-width: 768px) {
    width: 400px;
  }
`;

const Btn = styled.div`
  // background-image: url(https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/da5ac9a3-4e44-4e00-b113-753020d06100/public);
  background-color: #4783ED;
  width: 310px;
  padding: 10px 0px;
  border-radius: 8px;
  font-weight: 700;
  background-size: 100% 100%;
  margin: 10px auto 0px auto;
  position: relative;
  animation: pulse 1s infinite;
  color: black;
  font-size: 15px;
  
  @keyframes pulse {
    0% { transform: scaleX(1)  }
    50% { transform: scale3d(1.05, 1.05, 1.05)  }
    to { transform: scaleX(1)  }
  }
`;

const scratchAnimation = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(-55deg); }
  100% { transform: rotate(0deg); }
`;

const Hand = styled.img`
  width: 20px !important;
  position: absolute;
  right: 15px;
  top: 10px;
  animation: ${scratchAnimation} 2s infinite;
`;



const Card: React.FC = () => {
  const [scratchedPercent, setScratchedPercent] = useState(0);
  const [animate, setAnimate] = useState(false); 
  const [cover, setCover] = useState(true);
  const [content, setContent] = useState(true);
  const [btn, setBtn] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [hiddeHeadline, setHiddeHeadline] = useState<boolean>(true);


  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = queryParams.get('_token');
  const [token, setToken] = useState<string | null>(tokenFromUrl);

  useEffect(() => {
    if (!token) {
      const newToken = 'uuid_1r7e0753m5ta_1r7e0753m5ta667c8174b80428.31485206';
      setToken(newToken);

      queryParams.set('_token', newToken);
      navigate(`${window.location.pathname}?${queryParams.toString()}`, { replace: true });
    }
  }, [token, navigate, window.location.pathname, window.location.search]);

  const handleScratch = (scratched: boolean) => {
    if (scratched) {
      setTimeout(() => {
        setBtn(false);
        setContent(false);
        setShowModal(true)
        setHiddeHeadline(false);
      }, 2000);
      setAnimate(true);
    }
  };

  const handleScratchedPercentChange = (percent: number) => {
    setScratchedPercent(percent);
    if (percent > 70) {
      handleScratch(true);
    }
  };

  return (

    <Main>
    <GlobalStyle />
      {
      content && (
        <>
          <Headline/>
          <Scratch>
            <BackgroundDiv>
              <Background onScratch={handleScratch} animate={animate} />
            </BackgroundDiv>
            <CardDiv>
              <ScratchCardDiv>
                {cover && (
                  <ScratchCard
                    width={300}
                    height={330}
                    image={`${Cover}`}
                    brushSize={30}
                    onScratch={handleScratch}
                    onScratchedPercentChange={handleScratchedPercentChange}
                  />
                )}
              </ScratchCardDiv>
            </CardDiv>
          </Scratch>
        </>
        )
      }
      {/* <ButtonDiv>
        {btn && (
          <button>
            <Btn>
              PASSE O DEDO PARA RASPAR
              <Hand src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/6fa11bd7-f975-43b2-5bdb-f78e986d9500/public" />
            </Btn>
          </button>
        )}
      </ButtonDiv> */}
      {showModal && <Modal hiddeHeadline={hiddeHeadline} token={token} showModal={showModal}/>}

    </Main>
  );
};

export default Card;
